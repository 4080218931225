// Copyright 2020 Dgraph Labs, Inc. and Contributors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.query-vars-editor {
    background-color: #fff;
    border-top: 1px solid #ccc;
    position: relative;
    z-index: 100;

    max-height: 142px;
    overflow-x: hidden;
    overflow-y: scroll;

    .btn {
        background-color: #fff;
        border: 1px solid var(--secondary);
        border-radius: 2px;
        margin: 4px;
        overflow: hidden;
        padding: 0 2px;
        text-align: center;
        $size: 24px;
        height: $size;
        font-size: 12px;
        line-height: $size;
    }

    .btn-drop-all {
        border: 1px solid transparent;
        color: var(--danger);
        float: right;
        opacity: 0;
        transition: all 200ms;
    }
    &:hover .btn-drop-all {
        opacity: 0.5;
        &:hover {
            opacity: 1;
            border: 1px solid var(--danger);
        }
    }

    .count {
        color: var(--secondary);
        font-size: 13px;
    }

    .vars {
        flex: 1;
        flex-direction: column;
        border-top: 1px solid var(--light);

        .var {
            display: flex;
            flex-direction: row;
            position: relative;

            &:hover {
                background-color: var(--light);
                .delete {
                    opacity: 1;
                }
            }

            .delete {
                border: none;
                background: none;
                color: var(--secondary);
                margin-top: 2px;
                opacity: 0;
                transition: opacity 50ms;
                position: absolute;
                right: 2px;
            }

            .controls {
                width: 30px;
                display: inline-block;
                border-right: 1px solid var(--light);
                text-align: center;

                .checkbox-send {
                    display: inline-block;
                    vertical-align: text-bottom;
                }
            }

            .content {
                flex: 1;
                margin: 0 4px;

                .edit-var {
                    width: 100%;
                    margin: 2px;
                    margin-left: 0;
                    font-family: monospace;
                    font-size: 13px;
                }
            }
        }
    }
}
