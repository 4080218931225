.license-warning {
    background-color: rgba(240, 185, 141, 0.9);
    border-radius: 4px;
    box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.25);
    left: 20vw;
    padding: 8px;
    position: absolute;
    right: 20vw;
    top: 10px;
    z-index: 10;

    &.expired {
        background-color: rgba(220, 53, 69, 0.9);
        a {
            color: rgb(0, 0, 238);
        }
    }

    .dismiss {
        background: transparent;
        border: none;
        float: right;
        font-weight: bold;
        font-size: 16px;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
