$separator-fullwidth: 8px;
$separator-margin: 4px;

.vertical-panel-layout {
    display: flex;
    flex-direction: row;
    position: relative;

    .separator {
        position: absolute;
        background-color: #eee;
        border: 1px solid #ccc;
        border-radius: 2px;
        cursor: col-resize;
        height: 100%;

        box-sizing: border-box;
        width: $separator-fullwidth;
    }

    .panel {
        background-color: transparent;
        margin-bottom: 0;

        &.first {
            margin-right: $separator-margin;
        }
        &.second {
            // Second panel flex-grows horizontally to occupy remaining width
            flex: 1;
            margin-left: $separator-margin;
            overflow-y: auto;
            max-height: 100%;
        }
    }
}
