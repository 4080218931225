body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}

#root {
    display: flex;
    flex-direction: row;
    height: 100vh;
    position: relative;
}

.click-capture {
    width: calc(100% - 358px);
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    opacity: 0.76;
    background: white;
}

.main-content {
    background-color: #ececec;

    display: flex;
    // Stretch main content to occupy full remaining width of the page.
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    padding-left: 15px;
}

:focus {
    outline: none;
}

.vis-tooltip {
    border: 1px solid #f3d98c;
    background-color: #fffad5;
    padding: 10px;
    margin: 10px;
    border-radius: 3px;
    height: auto;
    position: absolute !important;
}

#properties em {
    height: 100%;
}

#properties pre {
    height: 100%;
}

input.form-control {
    font-size: 16px !important;
}

.form-group {
    margin-bottom: 5px;
}

// Override the library default.
.hljs {
    background: none;
}
